export default (context, html) => html`
  <form name="register-form" id="register-form-container" @submit="${(e) => context.submitForm(e)}" novalidate>
    <fieldset>
      <div class="input-container ${context.form.email.valid}">
        <label id="email-label" for="email" aria-hidden="true">Email</label>
        <div class="input-wrapper">
          <input id="email" type="email" name="email" @blur="${(e) => context.validateEmail(e)}" @input="${(e) => context.updateEmail(e)}" value="${context.email}" autocomplete="email" aria-label="Enter your email address." aria-describedby="email-error-message" aria-labelledby="email-label" required>
          ${context.form.email.isRequired.valid ? '' : html`
            <span id="email-error-message" class="error-message" for="email" role="alert" aria-label="Error - Please enter an email address. It is a required field and must be filled in.">Please enter an email address.</span>
          `}
          ${context.form.email.isValidEmail.valid ? '' : html`
            <span id="email-error-message" class="error-message" for="email" role="alert" aria-label="Error - Please enter a valid email address. It is a required field and must be filled in.">Email format you entered is invalid.</span>
          `}
          ${context.form.email.isNotEmoji.valid ? '' : html`
            <span id="email-error-message" class="error-message" for="email" role="alert" aria-label="Error - Email format you entered is invalid">Email format you entered is invalid.</span>
          `}
        </div>
      </div>
      <div class="input-container ${context.form.confirmEmail.valid}">
        <label id="confirm-email-label" for="confirm-email" aria-hidden="true">Confirm<span class="u-br"></span> Email</label>
        <div class="input-wrapper">
          <input id="confirm-email" type="email" name="confirm-email" @blur="${(e) => context.validateConfirmEmail(e)}" onpaste="return false" @input="${(e) => context.updateConfirmEmail(e)}" value="${context.confirmEmail}" autocomplete="email" aria-label="Confirm your email address." aria-describedby="confirm-email-error-message" aria-labelledby="confirm-email-label" required>
          ${context.form.confirmEmail.isRequired.valid ? '' : html`
            <span id="confirm-email-error-message" class="error-message" for="confirm-email" role="alert" aria-label="Error - Please confirm your email address. It is a required field and must be filled in.">Please confirm your email.</span>
          `}
          ${context.form.confirmEmail.isEqual.valid ? '' : html`
            <span id="confirm-email-error-message" class="error-message" for="confirm-email" role="alert" aria-label="Error - Email address do not match.">Email addresses do not match.</span>
          `}
        </div>
      </div>
      <div class="input-container ${context.form.password.valid}">
        <label id="password-label" for="password" aria-hidden="true">Password</label>
        <div class="input-wrapper">
          <input id="password" type="password" name="password" @blur="${(e) => context.validatePassword(e)}" @input="${(e) => context.updatePassword(e)}" value="${context.password}" aria-label="Enter your password." aria-describedby="password-error-message" aria-labelledby="password-label" required>
          ${context.form.password.isRequired.valid ? '' : html`
            <span id="password-error-message" class="error-message" for="password" role="alert" aria-label="Error - Please enter a password. It is a required field and must be filled in.">Please enter a password.</span>
          `}
          ${context.form.password.minLength.valid ? '' : html`
            <span id="password-error-message" class="error-message" for="password" role="alert" aria-label="Error - Password must be longer than 5 characters.">Password must be longer than 5 characters.</span>
          `}
          ${context.form.password.isNotEmail.valid ? '' : html`
            <span id="password-error-message" class="error-message" for="password" role="alert" aria-label="Error - Password cannot be an email address.">Password can't be an email address.</span>
          `}
          ${context.form.password.isNotAllDigits.valid ? '' : html`
            <span id="password-error-message" class="error-message" for="password" role="alert" aria-label="Error - Password cannot consist of only digits.">Password can't have only digits.</span>
          `}
        </div>
      </div>
      <div class="input-container ${context.form.confirmPassword.valid}">
        <label id="confirm-password-label" for="confirm-password" aria-hidden="true">Confirm<span class="u-br"></span> Password</label>
        <div class="input-wrapper">
          <input id="confirm-password" type="password" name="confirm-password" @blur="${(e) => context.validateConfirmPassword(e)}" @input="${(e) => context.updateConfirmPassword(e)}" onpaste="return false;" value="${context.confirmPassword}" aria-label="Enter your password again to confirm." aria-describedby="confirm-password-error-message" aria-labelledby="confirm-password-label" required>
          ${context.form.confirmPassword.isRequired.valid ? '' : html`
            <span id="confirm-password-error-message" class="error-message" for="confirm-password" role="alert" aria-label="Error - Please confirm your password.">Please confirm your password.</span>
          `}
          ${context.form.confirmPassword.isEqual.valid ? '' : html`
            <span id="confirm-password-error-message" class="error-message" for="confirm-password" role="alert" aria-label="Error - Passwords do not match.">Passwords do not match.</span>
          `}
        </div>
      </div>
    </fieldset>
    <button dom-scope="n0+jnS9p" id="register-submit-button" type="submit" tabindex="-1" aria-hidden="true">Register Submit Button</button>
  </form>
`;
