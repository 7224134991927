import {environmentTypeEnumerate} from '@aofl/server-environment';
import {environment as serverEnvironment} from '../server-environment';

export default (ctx, html) => html`
  <div class="${ctx.headerOverlay? 'show-overlay': ''}"><slot name="page-header"></slot></div>
  <div class="${ctx['full-height']? 'full-height': ''} ${ctx.contentOverlay? 'show-overlay': ''}"><slot name="page-content"></slot></div>
  <div class="${ctx.footerOverlay? 'show-overlay': ''}"><slot name="page-footer"></slot></div>
  ${serverEnvironment === environmentTypeEnumerate.DEV || serverEnvironment === environmentTypeEnumerate.STAGE ? html`
    <button dom-scope="LBUbpFVj" id="debug-menu" class="${ctx.displayDebugMenu ? '' : 'inactive'}" @click="${(e) => ctx.showDebugMenu(e)}">Debug Menu</button>
  ` : ''}
`;
