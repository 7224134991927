import '../back-button';
import '../close-app-button';
import '../link-to-element';

import {pages} from '../constants-enumerate';
import '../picture';

export default (ctx, html) => html`

  ${ctx.displayBackButton ? html`
    <div class="back-button-container">
      <back-button></back-button>
    </div>
  ` : ''}

  ${ctx.displayCloseAppButton ? html`
    <div class="side-container">
      <close-app-button class="link button yellow">Exit Game</close-app-button>
    </div>
  ` : ''}

  ${!ctx.displayCloseAppButton && !ctx.displayBackButton ? html`
    <div class="side-container">
      <slot name="left"></slot>
    </div>
  ` : ''}

  <div id="skip-navigation">
    <slot name="skip-navigation"></slot>
  </div>

  ${ctx.preventRedirect ? html`
    ${ctx.isNmhpLogo ? html`
      <aofl-picture id="aofl-logo-nmhp">
        <aofl-source media="(max-width: 768px)" srcset="/assets/templates/new-header/adventure-academy-no-badge-logo-mb-v1.png" width="453" height="36" alt="Logo for Adventure Academy from the Creators of ABCmouse. For Kids age 8 to 13."></aofl-source>
        <aofl-img src="/assets/templates/new-header/sub-header-dt-v1-4x.png" width="1381" height="265" alt="Logo for Adventure Academy from the Creators of ABCmouse. For Kids age 8 to 13."></aofl-img>
      </aofl-picture>`
      : html`
      <aofl-picture id="aofl-logo">
        <aofl-source media="(max-width: 768px)" srcset="/assets/templates/new-header/adventure-academy-no-badge-logo-mb-v1.png" alt="Logo for Adventure Academy from the Creators of ABCmouse. For Kids age 8 to 13."></aofl-source>
        <aofl-img src="/assets/templates/new-header/logo-ribbon-dt-v2-5x.png" width="489" height="254" alt="Logo for Adventure Academy from the Creators of ABCmouse. For Kids age 8 to 13."></aofl-img>
      </aofl-picture> `}
   ` : html`
   ${ctx.isNmhpLogo ? html`
    <link-to dom-scope="u5e27d4L" id="logo" href="${pages.HOME}" tab-outline-blue>
      <aofl-picture id="aofl-logo-nmhp">
        <aofl-source media="(max-width: 768px)" srcset="/assets/templates/new-header/adventure-academy-no-badge-logo-mb-v1.png" width="453" height="36" alt="Logo for Adventure Academy from the Creators of ABCmouse. For Kids age 8 to 13."></aofl-source>
        <aofl-img src="/assets/templates/new-header/sub-header-dt-v1-4x.png" width="1381" height="265" alt="Logo for Adventure Academy from the Creators of ABCmouse. For Kids age 8 to 13."></aofl-img>
      </aofl-picture>
    </link-to>
    ` : html`
    <link-to dom-scope="u5e27d4L" id="logo" href="${pages.HOME}" tab-outline-blue>
      <aofl-picture id="aofl-logo">
        <aofl-source media="(max-width: 768px)" srcset="/assets/templates/new-header/adventure-academy-no-badge-logo-mb-v1.png" alt="Logo for Adventure Academy from the Creators of ABCmouse. For Kids age 8 to 13."></aofl-source>
        <aofl-img src="/assets/templates/new-header/logo-ribbon-dt-v2-5x.png" width="489" height="254" alt="Logo for Adventure Academy from the Creators of ABCmouse. For Kids age 8 to 13."></aofl-img>
      </aofl-picture>
    </link-to>
    `
    }
  `}

  <div id="right" class="side-container">
    <slot name="right"></slot>
  </div>
`;
