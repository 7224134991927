import styles from './template.css';
import template from './template';
import {AoflElement, customElement} from '@aofl/element';
import {clickTrackerMixin} from '../click-tracker-mixin';

/**
 * @summary NewFooter
 * @class NewFooter
 * @extends {AoflElement}
 */
@customElement('new-footer')
class NewFooter extends clickTrackerMixin(AoflElement) {
  /**
   *
   * Creates an instance of NewFooter.
   */
  constructor() {
    super();
  }

  /**
   * @readonly
   */
  static is = 'new-footer';

  /**
   *
   * @return {Object}
   */
  render() {
    return super.render(template, [styles]);
  }
}

export default NewFooter;
