/* eslint-disable */
import {isNotQualified} from '@aoflmkt/is-webp-qualified';

const extension = isNotQualified ? 'png' : 'webp';

const imageConfig = {
  eyeOpen: {
    src: `/assets/routes/subscription/eye_open_v1.${extension}`,
    alt: 'an open eye'
  },
  eyeClosed: {
    src: `/assets/routes/subscription/eye_closed_v1.${extension}`,
    alt: 'a closed eye'
  },
  xIcon: {
    src: `/assets/routes/subscription/x_icon_v1.${extension}`,
    alt: 'an x icon'
  },
};

export {
  imageConfig
};
