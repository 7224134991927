import styles from './template.css';
import template from './template';
import {AoflElement, customElement, property} from '@aofl/element';
import {accessibilityMixin} from '../../modules/accessibility-mixin';
import {clickTrackerMixin} from '../../modules/click-tracker-mixin';
import {ModalService} from '../../modules/modal-service';
import {cookies} from '../../modules/constants-enumerate';
import Cookies from 'js-cookie';

/**
 * @summary UpdatedFooter
 * @extends {AoflElement}
 */
@customElement('updated-footer')
class UpdatedFooter extends clickTrackerMixin(accessibilityMixin(AoflElement)) {
  /**
   * @readonly
   */
  static is = 'updated-footer';

  @property({type: Number})
  currentYear = (new Date()).getFullYear();

  @property({type: Boolean, attribute: 'show-logout'})
  showLogout = false;

  @property({type: Boolean})
  showDoNotSellMyInfo = ((Cookies.get(cookies.COUNTRY_CODE) === 'US') && (Cookies.get(cookies.REGION) === 'CA'));

  /**
   *
   */
  showCookieConsentModal(e) {
    e.preventDefault();
    ModalService.show('cookieConsentModal');
  }

  /**
   *
   * @return {Object}
   */
  render() {
    return super.render(template, [styles]);
  }
}

export default UpdatedFooter;
