import styles from './template.css';
import template from './template.js';
import {customElement, property} from '@aofl/element';
import {PaymentForm} from '@aoflmkt/payment-form/modules/payment-form';
import {ModalService} from '../modal-service';
import {clickTrackerMixin} from '../click-tracker-mixin';
import {accessibilityMixin} from '../accessibility-mixin';
import {modalsConfig} from './__config/modals';

/**
 * @summary AcademyNewPaymentForm
 * @class AcademyNewPaymentForm
 * @extends {AoflElement}
 */
@customElement('academy-new-payment-form')
class AcademyNewPaymentForm extends clickTrackerMixin(accessibilityMixin(PaymentForm)) {
  /**
   *
   * Creates an instance of AcademyNewPaymentForm.
   */
  constructor() {
    super();
    ModalService.addModals(modalsConfig);
  }

  /**
   * @readonly
   */
  static is = 'academy-new-payment-form';

  @property({type: Boolean, attribute: true})
  ariaExpandedMonth = false;

  @property({type: Boolean, attribute: true})
  ariaExpandedYear = false;

  /**
   *
   */
  showCvvPopup() {
    ModalService.show('newCvvCodeModal');
  }

  /**
   * @param {Event} e
   */
  toggleMonth(e) {
    super.toggleMonth(e);
    this.ariaExpandedMonth = !this.ariaExpandedMonth;
    window.setTimeout(function() {
      const firstListItem = document.querySelector('body > route-view').shadowRoot.querySelector('#page-component').shadowRoot.querySelector('#payment-form').shadowRoot.querySelector('#month-option');
      firstListItem.focus({focusVisible: true});
    }, 100);
  }

  /**
   * @param {Event} e
   */
  updateCardMonth(e) {
    super.updateCardMonth(e);
    this.ariaExpandedMonth = false;
  }

  /**
   * @param {Event} e
   */
  toggleYear(e) {
    super.toggleYear(e);
    this.ariaExpandedYear = !this.ariaExpandedYear;
    window.setTimeout(function() {
      const firstListItem = document.querySelector('body > route-view').shadowRoot.querySelector('#page-component').shadowRoot.querySelector('#payment-form').shadowRoot.querySelector('#year-option');
      firstListItem.focus({focusVisible: true});
    }, 100);
  }

  /**
   * @param {Event} e
   */
  updateCardYear(e) {
    super.updateCardYear(e);
    this.ariaExpandedYear = false;
  }

  /**
   *
   * @return {Object}
   */
  render() {
    return super.render(template, [styles]);
  }
}

export default AcademyNewPaymentForm;
