import styles from './template.css';
import template from './template';
import {AoflElement, customElement, property} from '@aofl/element';
import {validationMixin, isRequired, minLength} from '@aofl/form-validate';
import {uncaughtApiErrorsValidator, isValidEmail, isNotEmoji, isNotEmail, isNotAllDigits} from '@aoflmkt/form-validators';
import {accessibilityMixin} from '../../modules/accessibility-mixin';
import {clickTrackerMixin} from '../../modules/click-tracker-mixin';
import {compactRegisterFormSdo} from './sdo';
import {cookies} from '../../modules/constants-enumerate';
import Cookies from 'js-cookie';

/**
 * @type {String}
 * @private
 */
const MIN_PWD_LENGTH = 5;

const Mixin = validationMixin(clickTrackerMixin(accessibilityMixin(AoflElement)));

/**
 * @summary CompactRegisterForm
 * @extends {validationMixin(clickTrackerMixin(accessibilityMixin(AoflElement)))}
 */
@customElement('compact-register-form')
class CompactRegisterForm extends Mixin {
  /**
   * Creates an instance of CompactRegisterForm.
   */
  constructor() {
    super();
    this.validators = this.defineValidators();
  }

  firstUpdated() {
    super.firstUpdated();

    compactRegisterFormSdo.email = Cookies.get(cookies.ELOG) || '';
    this.email = Cookies.get(cookies.ELOG) || '';

    let isSafari = navigator.userAgent.indexOf('Safari') > -1;
    const isChrome = navigator.userAgent.indexOf('Chrome') > -1;

    // MacOS Chrome has "Safari" in UA string
    if (isChrome && isSafari) {
      isSafari = false;
    }

    if (!isSafari) {
      this.autoFocus();
    }
  }

  /**
   * @readonly
   */
  static is = 'compact-register-form';
  @property({type: String, store: compactRegisterFormSdo, mapState: 'email'})
  email = '';

  @property({type: String, store: compactRegisterFormSdo, mapState: 'password'})
  password = '';

  @property({type: Boolean, store: compactRegisterFormSdo, mapState: 'showPassword'})
  showPassword = false;

  /**
   *
   */
  defineValidators() {
    return {
      email: {isRequired, isValidEmail, isNotEmoji},
      password: {isRequired, minLength: minLength(MIN_PWD_LENGTH), isNotEmail, isNotAllDigits},
      apiErrors: {
        uncaught: uncaughtApiErrorsValidator()
      }
    };
  }

  formSubmit(e) {
    e.preventDefault();
    this.dispatchEvent(new CustomEvent('formSubmit'));
  }

  /**
   * @param {Event} e
   */
  updateEmail(e) {
    compactRegisterFormSdo.email = e.target.value;
  }

  /**
   * @param {Event} e
   */
  updatePassword(e) {
    const doesNotContainSpaces = (value) => !/\s/.test(value);
    const removeSpaces = (value) => value.replace(/\s/g, '');

    if (doesNotContainSpaces(e.target.value)) {
      compactRegisterFormSdo.password = e.target.value;
      this.password = e.target.value;
    } else {
      const password = removeSpaces(e.target.value);
      compactRegisterFormSdo.password = password;
      e.target.value = password;
    }
  }


  /**
   *
   */
  toggleShowPassword() {
    compactRegisterFormSdo.showPassword = !this.showPassword;
  }

  /**
   *
   */
  resetEmail() {
    this.email = '';
    compactRegisterFormSdo.email = '';

    const emailInput = this.shadowRoot.querySelector('#email');
    emailInput.value = '';
    emailInput.focus();

    this.validateEmail();
  }

  /**
   *
   * @private
   */
  async validateEmail(e) {
    this.form.email.validate();
    await this.form.email.validateComplete;

    const isEmailValid = this.form.email.valid;

    return isEmailValid;
  }

  /**
   *
   * @private
   */
  async validatePassword() {
    this.form.password.validate();
    await this.form.password.validateComplete;

    const isPasswordValid = this.form.password.valid;

    return isPasswordValid;
  }

  /**
   *
   */
  autoFocus() {
    if (this.email) {
      const passwordInput = this.shadowRoot.querySelector('#password');
      passwordInput.focus({preventScroll: true});
    } else {
      const emailInput = this.shadowRoot.querySelector('#email');
      emailInput.focus({preventScroll: true});
    }
  }

  /**
   *
   * @return {Object}
   */
  render() {
    return super.render(template, [styles]);
  }
}

export default CompactRegisterForm;
