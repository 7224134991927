import {pages, environment, regStepsEnumerate, cookies} from './constants-enumerate';
import {routerInstance} from '@aofl/router';
import {userInfoSdo} from './sdo-user-info';
import {AppCallService} from './app-call-service';
import Cookies from 'js-cookie';

/**
 *
 * @class RegistrationPathService
 */
class RegistrationPathService {
  /**
   *
   * @static
   * @return {Function}
   */
  static next() {
    const isLoggedIn = userInfoSdo.userInfo.isLoggedIn;
    const nextStep = userInfoSdo.userInfo.nextStep;

    if (environment.IN_APP && isLoggedIn) {
      if (nextStep === regStepsEnumerate.COMPLETE) {
        if (localStorage.getItem('redirectToLogin')) {
          localStorage.removeItem('redirectToLogin');
        }

        Cookies.set('redirectToLogin', true, cookies.CONFIG);

        return this.makeLoginAppCall();
      }

      routerInstance.navigate(pages[nextStep], {});
    } else {
      routerInstance.navigate(pages[nextStep], {});
    }
  }

  /**
   *
   * @static
   */
  static makeLoginAppCall() {
    const userUuid = userInfoSdo.userInfo.userUuid;
    const userAccountType = userInfoSdo.userInfo.userAccountType;
    const appPayload = {
      token: Cookies.get(cookies.TOKEN),
      userUuid,
      userAccountType
    };

    AppCallService.login(appPayload);
  }
}

export {
  RegistrationPathService
};
