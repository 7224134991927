import {Sdo, storeInstance, state} from '@aofl/store';
import Cookies from 'js-cookie';
import {cookies} from '../../modules/constants-enumerate';

/**
 * @type {String}
 * @private
 */
const COMPACT_REGISTER_FORM = 'compact-register-form-sdo';

/**
 * @extends {Sdo}
 */
class CompactRegisterFormSdo extends Sdo {
  /**
   * @static
   * @type {String}
   */
  static namespace = COMPACT_REGISTER_FORM;

  /**
   * @type {String}
   */
  @state()
  email = Cookies.get(cookies.ELOG) || '';

  /**
   * @type {String}
   */
  @state()
  password = '';

  /**
   * @type {Boolean}
   */
  @state()
  showPassword = false;
}

/**
 * @type {CompactRegisterFormSdo}
 */
const compactRegisterFormSdo = new CompactRegisterFormSdo();
storeInstance.addState(compactRegisterFormSdo);

export {
  compactRegisterFormSdo
};
