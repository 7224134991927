import '../../modules/link-to-element';
import '../../modules/logout-button';
import {environment, pages, cookies, outboundLinks} from '../../modules/constants-enumerate';
import Cookies from 'js-cookie';

const isAU = Cookies.get(cookies.COUNTRY_CODE) === 'AU';

export default (ctx, html) => html`
  <slot></slot>
  <footer>
    ${environment.IS_ANDROID || environment.IS_LINK_FROM_APP ? html`
      <div id="aofl-text">
        TM &amp; &copy; 2007-${ctx.currentYear} <span class="enteractive tab-outline-white rm-tabindex" tabindex="0">Age of Learning, Inc.</span> All rights reserved.
      </div>
    ` : html`
      <div id="aofl-text">
        TM &amp; &copy; 2007-${ctx.currentYear} <link-to dom-scope="X4Kbec/Z" id="aofl-link" class="link" href="https://www.ageoflearning.com/" target="_blank" rel="noopener noreferrer" tab-focus-disabled show-parent-gate><span class="enteractive tab-outline-white rm-tabindex" tabindex="0">Age of Learning, Inc.</span></link-to> All rights reserved.
      </div>
    `}
    <div id="links">
      <span class="link" id="tandc">
        <link-to dom-scope="tSVyyc5F" href="${pages.TANDC}" target="_blank" rel="noopener noreferrer" tab-focus-disabled show-parent-gate><span class="enteractive tab-outline-white rm-tabindex" tabindex="0">Terms &amp; Conditions</span></link-to>
      </span>
      ${isAU ? html`
        <span class="divider" aria-hidden="true">|</span>
        <span class="link">
          <link-to dom-scope="lOr5PaeP" href="${pages.PRIVACY_POLICY}" target="_blank" rel="noopener noreferrer" tab-focus-disabled show-parent-gate><span class="enteractive tab-outline-white rm-tabindex" tabindex="0">Australian Privacy Statement &amp; Privacy Policy</span></link-to>
        </span>
      ` : html`
        <span class="divider" aria-hidden="true">|</span>
        <span class="link">
          <link-to dom-scope="Jng+uumg" href="${pages.PRIVACY_POLICY}" target="_blank" rel="noopener noreferrer" tab-focus-disabled show-parent-gate><span class="enteractive tab-outline-white rm-tabindex" tabindex="0">Privacy Policy</span></link-to>
        </span>
      `}
      ${ctx.showPrivacyPortal ? html`
        <span class="divider" aria-hidden="true">|</span>
        <span class="link">
          <link-to dom-scope="cPDCL1bT" target="_blank" tab-focus-disabled show-parent-gate><span class="enteractive tab-outline-white rm-tabindex" tabindex="0">Privacy Portal</span>link-to></link-to>
        </span>
      ` : ''}
      ${ctx.showDoNotSellMyInfo ? html `
        <span class="divider" aria-hidden="true">|</span>
        <span class="link">
          <a dom-scope="/eS2/ijV" class="enteractive tab-outline-white link" role="link" @click="${(e) => ctx.showCookieConsentModal(e)}" tabindex="0">Do Not Sell My Personal Information</a>
        </span>
      ` : ''}
      <span class="u-d-hide"></span>
      <span class="divider">|</span>
      <span class="link">
        <link-to dom-scope="uVziknu2" target="_blank" href="${pages.CUSTOMER_SUPPORT}" tab-focus-disabled show-parent-gate><span class="enteractive tab-outline-white rm-tabindex" tabindex="0">Customer Support</span></link-to>
      </span>
      ${ctx.showLogout ? html`
        <span class="divider" aria-hidden="true">|</span>
        <authstate-context class="link" show-when-loggedin>
          <logout-button dom-scope="TsIH7psK" class="enteractive tab-outline-white" tabindex="0">Log Out</logout-button>
        </authstate-context>
      ` : ''}
    </div>
    <link-to dom-scope="8xEqJgBv" href="${outboundLinks.KIDSAFE}" target="_blank" tab-outline-white show-parent-gate aria-label="kid safe"><img id="kidsafe-medium" width="130" height="50" border="0" alt="ABCmouse is certified by the kidSafe Seal Program." src="//www.kidsafeseal.com/sealimage/1643675032741265067/abcmouse_medium_darktm.png"/></link-to>
  </footer>
`;

