import '../../../../modules/back-button';
import '../../../../modules/close-app-button';
import {pages} from '../../../../modules/constants-enumerate';
import '../../../../modules/link-to-element';
import '../../../../modules/picture';

export default (ctx, html) => html`
  <main id="login-header">
    ${ctx.displayBackButton ? html`
      <div class="back-button-container">
        <back-button></back-button>
      </div>
    ` : ''}

    ${ctx.displayCloseAppButton ? html`
      <div class="side-container">
        <slot name="right"></slot>
      </div>
    ` : ''}

    ${!ctx.displayCloseAppButton && !ctx.displayBackButton ? html`
      <div class="side-container">
        <slot name="left"></slot>
      </div>
    ` : ''}

    <div id="skip-navigation">
      <slot name="skip-navigation"></slot>
    </div>

    <div id="header">
      ${ctx.preventRedirect ? html`
        <aofl-picture id="header-logo" class="enteractive">
          <aofl-source media="(max-width: 768px)" srcset="/assets/routes/login/adventure-academy-no-badge-logo-mb-v1.png"></aofl-source>
          <aofl-img id="header-img" src="/assets/routes/login/logo-ribbon-dt-v1-4x.png" width="594" height="180" alt="Adventure Academy from the creators of ABCmouse"></aofl-img>
        </aofl-picture>
      ` : html`
        <link-to dom-scope="xuSFZ4IQ" id="header-logo" role="navigation" href="${pages.HOME}" aria-label="Adventure Academy from the creators of A B C mouse" tab-outline-blue>
          <aofl-picture>
            <aofl-source media="(max-width: 768px)" srcset="/assets/routes/login/adventure-academy-no-badge-logo-mb-v1.png" alt="Adventure Academy Logo"></aofl-source>
            <aofl-img id="header-img" src="/assets/routes/login/logo-ribbon-dt-v1-4x.png" width="594" height="180" alt="Adventure Academy Logo"></aofl-img>
          </aofl-picture>
        </link-to>
      `}
    </div>

    <div id="right" class="side-container">
      <slot name="right"></slot>
    </div>
  </main>
`;
