import '@aofl/select';
import '@aofl/drawer';

export default (ctx, html) => html`
  <form id="payment-form-container" name="payment-form" @submit="${(e) => ctx.submitForm(e)}" novalidate>
    <fieldset class="cc-card-payment">
    <div class="input-container ${ctx.form.cardName.valid}">
        <label class="cc-input-label alegreya" for="card-name" aria-hidden="true">Cardholder<span class="u-br"></span> Name</label>
        <div class="input-wrapper">
          <input id="card-name" class="montserrat input" type="text" name="cc-name" @blur="${(e) => ctx.validateCardName(e)}" @input="${(e) => ctx.updateCardName(e)}" value="${ctx.cardName}" autocomplete="cc-name" aria-label="Enter the cardholder name." aria-describedby="card-name-error-message" aria-labelledby="card-name-label" aria-reqired="true" required>
          ${ctx.form.cardName.valid ? '' : html`
            <span class="error-message" for="card-name" role="alert" aria-label="Error - Please enter a valid cardholder name.">Please enter a valid cardholder name.</span>
          `}
        </div>
      </div>
      <div class="input-container ${ctx.form.cardNumber.valid}">
        <label class="cc-input-label alegreya" for="cc-num" aria-hidden="true">Credit Card<span class="u-br"></span> Number</label>
        <div class="input-wrapper">
          <input id="cc-num" class="montserrat input" type="tel" name="cc-number" @blur="${(e) => ctx.validateCardNumber(e)}" @input="${(e) => ctx.updateCardNumber(e)}" value="${ctx.cardNumber}" autocomplete="cc-number" aria-reqired="true" required>
          ${ctx.form.cardNumber.isRequired.valid ? '' : html`
            <span class="error-message" for="cc-num" autocomplete="cc-number" role="alert" aria-label="Please enter the card number." aria-describedby="cc-num-error-message" aria-labelledby="cc-num-label" aria-reqired="true">Please enter the card number.</span>
          `}
          ${ctx.form.cardNumber.isNotZero.valid ? '' : html`
            <span class="error-message" for="cc-num" role="alert" aria-label="Error - Please enter a valid credit card number.">Your card number is invalid.</span>
          `}
        </div>
      </div>
      <div class="input-container ${(ctx.form.expDate.valid && ctx.form.cardCvv.valid) ? '' : 'is-invalid'}">
        <label id="exp-date" class="alegreya" aria-hidden="true">Expiration<span class="u-br"></span> Date</label>
        <div id="dropdown-row">
          <div id="date-selector" class="${ctx.form.expDate.valid}">
            <div class="mkt-dropdown ${ctx.form.expDate.isNotExpired.valid ? '' : 'is-invalid'}" id="billing-info-month">
              <div class="dropdown-wrapper montserrat">
                <div class="button-wrapper enteractive ${ctx.form.expDate.isNotExpired.valid ? 'tab-outline-blue' : 'tab-outline-red'}" @click="${(e) => ctx.toggleMonth(e)}" aria-label="Use the drop down to enter the month of the credit card expiration date." tabindex="0" aria-expanded="${ctx.ariaExpandedMonth}" role="button" aria-describedby="exp-date">
                  <div class="display-box">
                    <span>${ctx.cardMonth}</span>
                  </div>
                  <div aria-hidden="true" class="dropdown-arrow">
                    <span>&#9662;</span>
                  </div>
                </div>
                <aofl-drawer animate-drawer="false" ?open="${ctx.monthDropdownActive}">
                  <aofl-select-list id="select-list-month" @change="${(e) => ctx.updateCardMonth(e)}" role="listbox">
                    ${ctx.months.map((month) => html`
                      <aofl-list-option id="month-option" class="montserrat enteractive" value=${month} tabindex="0" role="listitem">${month}</aofl-list-option>
                    `)}
                  </aofl-select-list>
                </aofl-drawer>
              </div>
            </div>

            <div class="mkt-dropdown" id="billing-info-year">
              <div class="dropdown-wrapper montserrat">
                <div class="button-wrapper enteractive tab-outline-blue" @click="${(e) => ctx.toggleYear(e)}" aria-label="Use the drop down to enter the year of the credit card expiration date." tabindex="0" aria-expanded="${ctx.ariaExpandedYear}" role="button" aria-describedby="exp-date">
                  <div class="display-box">
                    <span>${ctx.cardYear}</span>
                  </div>
                  <div aria-hidden="true" class="dropdown-arrow">
                    <span>&#9662;</span>
                  </div>
                </div>
                <aofl-drawer animate-drawer="false" ?open="${ctx.yearDropdownActive}">
                  <aofl-select-list id="select-list-year" @change="${(e) => ctx.updateCardYear(e)}" role="listbox">
                    ${ctx.years.map((year) => html`
                      <aofl-list-option id="year-option" class="montserrat enteractive" value=${year} tabindex="0" role="listitem">${year}</aofl-list-option>
                    `)}
                  </aofl-select-list>
                </aofl-drawer>
              </div>
            </div>
          </div>
          ${ctx.form.expDate.isNotExpired.valid ? '' : html`
            <span class="error-message" for="exp-date" aria-label="Error - Your expiration date is invalid.">Your expiration date is invalid.</span>
          `}
          <div id="cvv-wrapper" class="input-container">
            <div id="cvv-error-wrapper">
              <div id="cvv-container" class="error-wrapper ${ctx.form.cardCvv.valid}">
                <label class="cvv-input-label alegreya" for="cvv" aria-hidden="true">CVV</label>
                <input id="cvv" class="montserrat input" type="tel" maxlength="4" @input="${(e) => ctx.updateCardCvv(e)}" value="${ctx.cardCvv}" @blur="${(e) => ctx.validateCardCvv(e)}" aria-label="Enter the CVV of the credit card." aria-describedby="cvv-error-message" aria-labelledby="cvv-label" aria-reqired="true" required>
                ${ctx.form.cardCvv.isRequired.valid ? '' : html`
                  <span class="error-message ${ctx.form.cardCvv.isRequired.valid}" id="empty-error" for="ccv" aria-label="Error - Please enter your credit card security code.">Please enter security code.</span>
                `}
                ${ctx.form.cardCvv.isNotEmoji.valid && ctx.form.cardCvv.isNotZero.valid ? '' : html`
                  <span class="error-message ${ctx.form.cardCvv.isRequired.valid}" id="invalid-error" for="ccv" aria-label="Error - Please enter a valid credit card security code.">Your card security code is invalid.</span>
                `}
              </div>
              <button id="cvv-button" dom-scope="UJLWkMQg" class="button blue alegreya tab-outline-blue" type="button" @click="${(e) => ctx.showCvvPopup(e)}" aria-label="What is the CVV and where is it located on my credit card.">?</button>
            </div>
          </div>
        </div>
      </div>
      <div id="zip-code-input-container" class="input-container ${ctx.form.zipCode.valid}">
        <label class="cc-input-label alegreya" for="zipcode" aria-hidden="true">Billing Zip/<span class="u-br"></span>Postal Code</label>
        <div class="input-wrapper">
          <input id="zipcode" class="montserrat input" type="tel" name="postal-code" @blur="${(e) => ctx.validateZipCode(e)}" @input="${(e) => ctx.updateZipCode(e)}" placeholder="Gift/International cards: enter 12345" value="${ctx.zipCode}" autocomplete="postal-code" aria-label="Enter your Billing Zip or Postal Code. For gift cards or international cards enter 12345." aria-describedby="zipcode-error-message" aria-labelledby="zipcode-label" aria-reqired="true" required>
          ${ctx.form.zipCode.isRequired.valid ? '' : html`
            <span class="error-message" for="zipcode" aria-label="Error - Please provide your Billing Zip or Postal Code. For gift cards or international cards please enter 12345." >Please provide Zip or Postal Code.</span>
          `}
          ${ctx.form.zipCode.isNotZero.valid && ctx.form.zipCode.isNotEmoji.valid ? '' : html`
          <span class="error-message" for="zipcode" aria-label="Error - Please provide a valid Billing Zip or Postal Code. For gift cards or international cards please enter 12345.">Zip or Postal Code format you entered is invalid.</span>
          `}
        </div>
      </div>
      <div class="input-container">
        <label class="cc-input-label alegreya" for="phone-number" aria-hidden="true">Phone<span class="u-br"></span> Number</label>
        <div class="input-wrapper">
          <input id="phone-number" class="montserrat input" type="tel" name="tel" @input="${(e) => ctx.updatePhoneNumber(e)}" placeholder="Optional, but recommended" value="${ctx.phoneNumber}" autocomplete="tel" aria-labelledby="phone-number-label" aria-label="Enter your phone number. This is optional, but recommended.">
        </div>
      </div>
    </fieldset>
    <button dom-scope="ZF7TRMDr" id="payment-submit-button" type="submit" tabindex="-1" aria-hidden="true">Payment Submit Button</button>
  </form>
`;
