import styles from './template.css';
import template from './template.js';
import {customElement} from '@aofl/element';
import {RegisterForm} from '@aoflmkt/register-form';
import {clickTrackerMixin} from '../click-tracker-mixin';

/**
 * @summary AcademyNewRegisterForm
 * @class AcademyNewRegisterForm
 * @extends {AoflElement}
 */
@customElement('academy-new-register-form')
class AcademyNewRegisterForm extends clickTrackerMixin(RegisterForm) {
  /**
   *
   * Creates an instance of AcademyNewRegisterForm.
   */
  constructor() {
    super();
  }

  /**
   * @readonly
   */
  static is = 'academy-new-register-form';

  /**
   *
   * @private
   */
  disconnectedCallback() {
    super.disconnectedCallback();
  }

  /**
   *
   * @return {Object}
   */
  render() {
    return super.render(template, [styles]);
  }
}

export default AcademyNewRegisterForm;
