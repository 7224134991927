import {isNotQualified} from '@aoflmkt/is-webp-qualified';

const imageConfig = {
  exitGameButton: [
    {maxWidth: 1024, src: `${isNotQualified ? '/assets/routes/login/button-exit-game-dt-v1-1x.png' : '/assets/routes/login/button-exit-game-dt-v1-1x.webp'}`},
    {maxWidth: 1280, src: `${isNotQualified ? '/assets/routes/login/button-exit-game-dt-v1-2x.png' : '/assets/routes/login/button-exit-game-dt-v1-2x.webp'}`},
    {maxWidth: 1366, src: `${isNotQualified ? '/assets/routes/login/button-exit-game-dt-v1-3x.png' : '/assets/routes/login/button-exit-game-dt-v1-3x.webp'}`},
    {maxWidth: 1440, src: `${isNotQualified ? '/assets/routes/login/button-exit-game-dt-v1-4x.png' : '/assets/routes/login/button-exit-game-dt-v1-4x.webp'}`}
  ],
  signupButton: [
    {maxWidth: 1024, src: `${isNotQualified ? '/assets/routes/login/button-signup-dt-v1-1x.png' : '/assets/routes/login/button-signup-dt-v1-1x.webp'}`},
    {maxWidth: 1280, src: `${isNotQualified ? '/assets/routes/login/button-signup-dt-v1-2x.png' : '/assets/routes/login/button-signup-dt-v1-2x.webp'}`},
    {maxWidth: 1366, src: `${isNotQualified ? '/assets/routes/login/button-signup-dt-v1-3x.png' : '/assets/routes/login/button-signup-dt-v1-3x.webp'}`},
    {maxWidth: 1440, src: `${isNotQualified ? '/assets/routes/login/button-signup-dt-v1-4x.png' : '/assets/routes/login/button-signup-dt-v1-4x.webp'}`}
  ],
  loginButton: [
    {maxWidth: 320, src: `${isNotQualified ? '/assets/routes/login/button-login-mb-v1-1x.png' : '/assets/routes/login/button-login-mb-v1-1x.webp'}`},
    {maxWidth: 480, src: `${isNotQualified ? '/assets/routes/login/button-login-mb-v1-2x.png' : '/assets/routes/login/button-login-mb-v1-2x.webp'}`},
    {maxWidth: 576, src: `${isNotQualified ? '/assets/routes/login/button-login-mb-v1-3x.png' : '/assets/routes/login/button-login-mb-v1-3x.webp'}`},
    {maxWidth: 768, src: `${isNotQualified ? '/assets/routes/login/button-login-mb-v1-4x.png' : '/assets/routes/login/button-login-mb-v1-4x.webp'}`},
    {maxWidth: 1024, src: `${isNotQualified ? '/assets/routes/login/button-login-dt-v1-1x.png' : '/assets/routes/login/button-login-dt-v1-1x.webp'}`},
    {maxWidth: 1280, src: `${isNotQualified ? '/assets/routes/login/button-login-dt-v1-2x.png' : '/assets/routes/login/button-login-dt-v1-2x.webp'}`},
    {maxWidth: 1366, src: `${isNotQualified ? '/assets/routes/login/button-login-dt-v1-3x.png' : '/assets/routes/login/button-login-dt-v1-3x.webp'}`},
    {maxWidth: 1440, src: `${isNotQualified ? '/assets/routes/login/button-login-dt-v1-4x.png' : '/assets/routes/login/button-login-dt-v1-4x.webp'}`}
  ],
  learnMoreButton: [
    {maxWidth: 320, src: `${isNotQualified ? '/assets/routes/login/button-learn-more-mb-v1-1x.png' : '/assets/routes/login/button-learn-more-mb-v1-1x.webp'}`},
    {maxWidth: 480, src: `${isNotQualified ? '/assets/routes/login/button-learn-more-mb-v1-2x.png' : '/assets/routes/login/button-learn-more-mb-v1-2x.webp'}`},
    {maxWidth: 576, src: `${isNotQualified ? '/assets/routes/login/button-learn-more-mb-v1-3x.png' : '/assets/routes/login/button-learn-more-mb-v1-3x.webp'}`},
    {maxWidth: 768, src: `${isNotQualified ? '/assets/routes/login/button-learn-more-mb-v1-4x.png' : '/assets/routes/login/button-learn-more-mb-v1-4x.webp'}`},
    {maxWidth: 1024, src: `${isNotQualified ? '/assets/routes/login/button-learn-more-dt-v1-1x.png' : '/assets/routes/login/button-learn-more-dt-v1-1x.webp'}`},
    {maxWidth: 1280, src: `${isNotQualified ? '/assets/routes/login/button-learn-more-dt-v1-2x.png' : '/assets/routes/login/button-learn-more-dt-v1-2x.webp'}`},
    {maxWidth: 1366, src: `${isNotQualified ? '/assets/routes/login/button-learn-more-dt-v1-3x.png' : '/assets/routes/login/button-learn-more-dt-v1-3x.webp'}`},
    {maxWidth: 1440, src: `${isNotQualified ? '/assets/routes/login/button-learn-more-dt-v1-4x.png' : '/assets/routes/login/button-learn-more-dt-v1-4x.webp'}`}
  ],
  clickHereButton: [
    {maxWidth: 320, src: `${isNotQualified ? '/assets/routes/login/button-click-here-mb-v1-1x.png' : '/assets/routes/login/button-click-here-mb-v1-1x.webp'}`},
    {maxWidth: 480, src: `${isNotQualified ? '/assets/routes/login/button-click-here-mb-v1-2x.png' : '/assets/routes/login/button-click-here-mb-v1-2x.webp'}`},
    {maxWidth: 576, src: `${isNotQualified ? '/assets/routes/login/button-click-here-mb-v1-3x.png' : '/assets/routes/login/button-click-here-mb-v1-3x.webp'}`},
    {maxWidth: 768, src: `${isNotQualified ? '/assets/routes/login/button-click-here-mb-v1-4x.png' : '/assets/routes/login/button-click-here-mb-v1-4x.webp'}`},
    {maxWidth: 1024, src: `${isNotQualified ? '/assets/routes/login/button-click-here-dt-v1-1x.png' : '/assets/routes/login/button-click-here-dt-v1-1x.webp'}`},
    {maxWidth: 1280, src: `${isNotQualified ? '/assets/routes/login/button-click-here-dt-v1-2x.png' : '/assets/routes/login/button-click-here-dt-v1-2x.webp'}`},
    {maxWidth: 1366, src: `${isNotQualified ? '/assets/routes/login/button-click-here-dt-v1-3x.png' : '/assets/routes/login/button-click-here-dt-v1-3x.webp'}`},
    {maxWidth: 1440, src: `${isNotQualified ? '/assets/routes/login/button-click-here-dt-v1-4x.png' : '/assets/routes/login/button-click-here-dt-v1-4x.webp'}`}
  ],
  facebook: [{src: `${isNotQualified ? '/assets/templates/social-media-icons/facebook-v1.png' : '/assets/templates/social-media-icons/facebook-v1.webp'}`}],
  instagram: [{src: `${isNotQualified ? '/assets/templates/social-media-icons/instagram-v1.png' : '/assets/templates/social-media-icons/instagram-v1.webp'}`}],
  twitter: [{src: `${isNotQualified ? '/assets/templates/social-media-icons/twitter-v1.png' : '/assets/templates/social-media-icons/twitter-v1.webp'}`}],
  youtube: [{src: `${isNotQualified ? '/assets/templates/social-media-icons/youtube-v1.png' : '/assets/templates/social-media-icons/youtube-v1.webp'}`}],
  blueRibbonTop: [
    {maxWidth: 320, src: `${isNotQualified ? '/assets/routes/home/ribbon-blue-top-mb-v1-1x.png' : '/assets/routes/login/ribbon-blue-top-mb-v1-1x.webp'}`},
    {maxWidth: 480, src: `${isNotQualified ? '/assets/routes/login/ribbon-blue-top-mb-v1-2x.png' : '/assets/routes/login/ribbon-blue-top-mb-v1-2x.webp'}`},
    {maxWidth: 576, src: `${isNotQualified ? '/assets/routes/login/ribbon-blue-top-mb-v1-3x.png' : '/assets/routes/login/ribbon-blue-top-mb-v1-3x.webp'}`},
    {maxWidth: 768, src: `${isNotQualified ? '/assets/routes/login/ribbon-blue-top-mb-v1-4x.png' : '/assets/routes/login/ribbon-blue-top-mb-v1-4x.webp'}`},
  ]
};

export {
  imageConfig
};
