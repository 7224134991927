import {imageConfig} from './__config/images';

export default (ctx, html) => html`
  <form @submit="${(e) => ctx.formSubmit(e)}">
    <div class="input-container">
      <label for="email">Email</label>
      <div class="input-wrapper">
        <input id="email" class="${!ctx.form.email.valid ? 'error' : ''}" type="email" name="email" @blur="${(e) => ctx.validateEmail(e)}" @input="${(e) => ctx.updateEmail(e)}" value="${ctx.email}" autocomplete="email" aria-label="Enter your email address." aria-describedby="email-error-message" aria-invalid="${ctx.form.email.valid ? 'false' : 'true'}"/>
        <img class="${`eye-img enteractive tab-outline-blue ${!ctx.email ? 'hide' : ''}`}" src="${imageConfig.xIcon.src}" width="24" height="16" alt="" @click="${() => ctx.resetEmail()}" tabindex="0">
        ${ctx.form.email.isRequired.valid ? '' : html`
          <span id="email-error-message" class="error-message" for="email" role="alert status" aria-label="Error - Please enter an email address. It is a required field and must be filled in." aria-live="polite">Please enter an email address.</span>
        `}
        ${ctx.form.email.isValidEmail.valid && ctx.form.email.isNotEmoji.valid ? '' : html`
          <span id="email-error-message" class="error-message" for="email" role="alert status" aria-label="Error - Please enter a valid email address. It is a required field and must be filled in." aria-live="polite">Email format you entered is invalid.</span>
        `}
      </div>
    </div>
    <div class="input-container">
      <label for="password">Password</label>
      <div class="input-wrapper">
        <input id="password" class="${!ctx.form.password.valid ? 'error' : ''}" type="${ctx.showPassword ? 'text' : 'password'}" @blur="${(e) => ctx.validatePassword(e)}" @input="${(e) => ctx.updatePassword(e)}" value="${ctx.password}" autocomplete="new-password" aria-label="Enter your password." aria-describedby="password-error-message" aria-invalid="${ctx.form.password.valid ? 'false' : 'true'}"/>
        <img class="${`eye-img enteractive tab-outline-blue ${!ctx.showPassword ? 'hide' : ''}`}" src="${imageConfig.eyeOpen.src}" width="24" height="16" alt="" @click="${() => ctx.toggleShowPassword()}" tabindex="0">
        <img class="${`eye-img enteractive tab-outline-blue ${ctx.showPassword ? 'hide' : ''}`}" src="${imageConfig.eyeClosed.src}" width="24" height="16" alt="" @click="${() => ctx.toggleShowPassword()}" tabindex="0">
        ${ctx.form.password.isRequired.valid ? '' : html`
          <span id="password-error-message" class="error-message" for="password" role="alert status" aria-label="Error - Please enter a password. It is a required field and must be filled in." aria-live="polite">Please enter a password.</span>
        `}
        ${ctx.form.password.minLength.valid ? '' : html`
          <span id="password-error-message" class="error-message" for="password" role="alert status" aria-label="Error - Password must be at lease 5 characters.  It is a required field and must be filled in." aria-live="polite">Password must be at least 5 characters.</span>
        `}
        ${ctx.form.password.isNotEmail.valid ? '' : html`
          <span id="password-error-message" class="error-message" for="password" role="alert status" aria-label="Error - Password cannot be an email address.  It is a required field and must be filled in." aria-live="polite">Password can't be an email address.</span>
        `}
        ${ctx.form.password.isNotAllDigits.valid ? '' : html`
          <span id="password-error-message" class="error-message" for="password" role="alert status" aria-label="Error - Password cannot consist of only digits.  It is a required field and must be filled in." aria-live="polite">Password can't have only digits.</span>
        `}
      </div>
    </div>
    <button dom-scope="+EcRCUa8" id="submit" @click=${(e) => ctx.formSubmit(e)}>Continue</button>
  </form>
`;
