import styles from './template.css';
import template from './template.js';
import {customElement} from '@aofl/element';
import {RegisterForm} from '@aoflmkt/register-form';
import {clickTrackerMixin} from '../click-tracker-mixin';

/**
 * @summary AcademyRegisterForm
 * @class AcademyRegisterForm
 * @extends {AoflElement}
 */
@customElement('academy-register-form')
class AcademyRegisterForm extends clickTrackerMixin(RegisterForm) {
  /**
   * @readonly
   */
  static is = 'academy-register-form';

  /**
   *
   * @return {Object}
   */
  render() {
    return super.render(template, [styles]);
  }
}

export default AcademyRegisterForm;
