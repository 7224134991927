/* eslint-disable */
import {isNotQualified} from '@aoflmkt/is-webp-qualified';

const imageConfig = {
  loginButton: [
    {maxWidth: 768, src: `${isNotQualified ? '/assets/routes/home/button-login-dt-v3-4x.png' : '/assets/routes/home/button-login-dt-v3-4x.png'}`},
    {maxWidth: 1024, src: `${isNotQualified ? '/assets/routes/home/button-login-dt-v3-1x.png' : '/assets/routes/home/button-login-dt-v3-1x.webp'}`},
    {maxWidth: 1280, src: `${isNotQualified ? '/assets/routes/home/button-login-dt-v3-2x.png' : '/assets/routes/home/button-login-dt-v3-2x.webp'}`},
    {maxWidth: 1366, src: `${isNotQualified ? '/assets/routes/home/button-login-dt-v3-3x.png' : '/assets/routes/home/button-login-dt-v3-3x.webp'}`},
    {maxWidth: 1440, src: `${isNotQualified ? '/assets/routes/home/button-login-dt-v3-4x.png' : '/assets/routes/home/button-login-dt-v3-4x.webp'}`}
  ],
  logoutButton: [
    {maxWidth: 1024, src: `${isNotQualified ? '/assets/routes/home/button-logout-dt-v1-1x.png' : '/assets/routes/home/button-logout-dt-v1-1x.webp'}`},
    {maxWidth: 1280, src: `${isNotQualified ? '/assets/routes/home/button-logout-dt-v1-2x.png' : '/assets/routes/home/button-logout-dt-v1-2x.webp'}`},
    {maxWidth: 1366, src: `${isNotQualified ? '/assets/routes/home/button-logout-dt-v1-3x.png' : '/assets/routes/home/button-logout-dt-v1-3x.webp'}`},
    {maxWidth: 1440, src: `${isNotQualified ? '/assets/routes/home/button-logout-dt-v1-4x.png' : '/assets/routes/home/button-logout-dt-v1-4x.webp'}`}
  ],
  signupButton: [
    {maxWidth: 768, src: `${isNotQualified ? '/assets/routes/home/button-signup-dt-v3-4x.png' : '/assets/routes/home/button-signup-dt-v3-4x.png'}`},
    {maxWidth: 1024, src: `${isNotQualified ? '/assets/routes/home/button-signup-dt-v3-1x.png' : '/assets/routes/home/button-signup-dt-v3-1x.webp'}`},
    {maxWidth: 1280, src: `${isNotQualified ? '/assets/routes/home/button-signup-dt-v3-2x.png' : '/assets/routes/home/button-signup-dt-v3-2x.webp'}`},
    {maxWidth: 1366, src: `${isNotQualified ? '/assets/routes/home/button-signup-dt-v3-3x.png' : '/assets/routes/home/button-signup-dt-v3-3x.webp'}`},
    {maxWidth: 1440, src: `${isNotQualified ? '/assets/routes/home/button-signup-dt-v3-4x.png' : '/assets/routes/home/button-signup-dt-v3-4x.webp'}`}
  ],
  topRegBox: [
    {maxWidth: 320, src: `${isNotQualified ? '/assets/routes/home/nmhp-minireg-box-mb-v2-1x.png' : '/assets/routes/home/nmhp-minireg-box-mb-v2-1x.webp'}`},
    {maxWidth: 480, src: `${isNotQualified ? '/assets/routes/home/nmhp-minireg-box-mb-v2-2x.png' : '/assets/routes/home/nmhp-minireg-box-mb-v2-2x.webp'}`},
    {maxWidth: 576, src: `${isNotQualified ? '/assets/routes/home/nmhp-minireg-box-mb-v2-3x.png' : '/assets/routes/home/nmhp-minireg-box-mb-v2-3x.webp'}`},
    {maxWidth: 768, src: `${isNotQualified ? '/assets/routes/home/nmhp-minireg-box-mb-v2-4x.png' : '/assets/routes/home/nmhp-minireg-box-mb-v2-4x.webp'}`}
  ],
  gameplayTrailerButton: [
    {maxWidth: 320, src: `${isNotQualified ? '/assets/routes/home/button-trailer-mb-v1-1x.png' : '/assets/routes/home/button-trailer-mb-v1-1x.webp'}`},
    {maxWidth: 480, src: `${isNotQualified ? '/assets/routes/home/button-trailer-mb-v1-2x.png' : '/assets/routes/home/button-trailer-mb-v1-2x.webp'}`},
    {maxWidth: 576, src: `${isNotQualified ? '/assets/routes/home/button-trailer-mb-v1-3x.png' : '/assets/routes/home/button-trailer-mb-v1-3x.webp'}`},
    {maxWidth: 768, src: `${isNotQualified ? '/assets/routes/home/button-trailer-mb-v1-4x.png' : '/assets/routes/home/button-trailer-mb-v1-4x.webp'}`},
    {maxWidth: 1024, src: `${isNotQualified ? '/assets/routes/home/button-trailer-dt-v4-1x.png' : '/assets/routes/home/button-trailer-dt-v4-1x.webp'}`},
    {maxWidth: 1280, src: `${isNotQualified ? '/assets/routes/home/button-trailer-dt-v4-2x.png' : '/assets/routes/home/button-trailer-dt-v4-2x.webp'}`},
    {maxWidth: 1366, src: `${isNotQualified ? '/assets/routes/home/button-trailer-dt-v4-3x.png' : '/assets/routes/home/button-trailer-dt-v4-3x.webp'}`},
    {maxWidth: 1440, src: `${isNotQualified ? '/assets/routes/home/button-trailer-dt-v4-4x.png' : '/assets/routes/home/button-trailer-dt-v4-4x.webp'}`}
  ],
  redSwoosh: [
    {maxWidth: 320, src: `${isNotQualified ? '/assets/routes/home/red-swoosh-mb-v1-1x.png' : '/assets/routes/home/red-swoosh-mb-v1-1x.webp'}`},
    {maxWidth: 480, src: `${isNotQualified ? '/assets/routes/home/red-swoosh-mb-v1-2x.png' : '/assets/routes/home/red-swoosh-mb-v1-2x.webp'}`},
    {maxWidth: 576, src: `${isNotQualified ? '/assets/routes/home/red-swoosh-mb-v1-3x.png' : '/assets/routes/home/red-swoosh-mb-v1-3x.webp'}`},
    {maxWidth: 768, src: `${isNotQualified ? '/assets/routes/home/red-swoosh-mb-v1-4x.png' : '/assets/routes/home/red-swoosh-mb-v1-4x.webp'}`},
    {maxWidth: 1024, src: `${isNotQualified ? '/assets/routes/home/red-swoosh-dt-v1-1x.png' : '/assets/routes/home/red-swoosh-dt-v1-1x.webp'}`},
    {maxWidth: 1280, src: `${isNotQualified ? '/assets/routes/home/red-swoosh-dt-v1-2x.png' : '/assets/routes/home/red-swoosh-dt-v1-2x.webp'}`},
    {maxWidth: 1366, src: `${isNotQualified ? '/assets/routes/home/red-swoosh-dt-v1-3x.png' : '/assets/routes/home/red-swoosh-dt-v1-3x.webp'}`},
    {maxWidth: 1440, src: `${isNotQualified ? '/assets/routes/home/red-swoosh-dt-v1-4x.png' : '/assets/routes/home/red-swoosh-dt-v1-4x.webp'}`},
    {maxWidth: 1920, src: `${isNotQualified ? '/assets/routes/home/red-swoosh-dt-v1-5x.png' : '/assets/routes/home/red-swoosh-dt-v1-5x.webp'}`}
  ],
  annualOffer: [
    {maxWidth: 320, src: `${isNotQualified ? '/assets/routes/home/nmhp-offer-mb-v2-1x.png' : '/assets/routes/home/nmhp-offer-mb-v2-1x.webp'}`},
    {maxWidth: 480, src: `${isNotQualified ? '/assets/routes/home/nmhp-offer-mb-v2-2x.png' : '/assets/routes/home/nmhp-offer-mb-v2-2x.webp'}`},
    {maxWidth: 576, src: `${isNotQualified ? '/assets/routes/home/nmhp-offer-mb-v2-3x.png' : '/assets/routes/home/nmhp-offer-mb-v2-3x.webp'}`},
    {maxWidth: 768, src: `${isNotQualified ? '/assets/routes/home/nmhp-offer-mb-v2-4x.png' : '/assets/routes/home/nmhp-offer-mb-v2-4x.webp'}`},
    {maxWidth: 1024, src: `${isNotQualified ? '/assets/routes/home/nmhp-offer-dt-v5-1x.png' : '/assets/routes/home/nmhp-offer-dt-v5-1x.webp'}`},
    {maxWidth: 1280, src: `${isNotQualified ? '/assets/routes/home/nmhp-offer-dt-v5-2x.png' : '/assets/routes/home/nmhp-offer-dt-v5-2x.webp'}`},
    {maxWidth: 1366, src: `${isNotQualified ? '/assets/routes/home/nmhp-offer-dt-v5-3x.png' : '/assets/routes/home/nmhp-offer-dt-v5-3x.webp'}`},
    {maxWidth: 1440, src: `${isNotQualified ? '/assets/routes/home/nmhp-offer-dt-v5-4x.png' : '/assets/routes/home/nmhp-offer-dt-v5-4x.webp'}`}
  ],
  blueRibbonTop: [
    {maxWidth: 320, src: `${isNotQualified ? '/assets/routes/home/ribbon-blue-top-mb-v1-1x.png' : '/assets/routes/home/ribbon-blue-top-mb-v1-1x.webp'}`},
    {maxWidth: 480, src: `${isNotQualified ? '/assets/routes/home/ribbon-blue-top-mb-v1-2x.png' : '/assets/routes/home/ribbon-blue-top-mb-v1-2x.webp'}`},
    {maxWidth: 576, src: `${isNotQualified ? '/assets/routes/home/ribbon-blue-top-mb-v1-3x.png' : '/assets/routes/home/ribbon-blue-top-mb-v1-3x.webp'}`},
    {maxWidth: 768, src: `${isNotQualified ? '/assets/routes/home/ribbon-blue-top-mb-v1-4x.png' : '/assets/routes/home/ribbon-blue-top-mb-v1-4x.webp'}`},
  ],
  tryItFreeVideo: [
    {maxWidth: 320, src: `${isNotQualified ? '/assets/routes/home/try-it-free-banner-mb-v3-1x.png' : '/assets/routes/home/try-it-free-banner-mb-v3-1x.webp'}`},
    {maxWidth: 480, src: `${isNotQualified ? '/assets/routes/home/try-it-free-banner-mb-v3-2x.png' : '/assets/routes/home/try-it-free-banner-mb-v3-2x.webp'}`},
    {maxWidth: 576, src: `${isNotQualified ? '/assets/routes/home/try-it-free-banner-mb-v3-3x.png' : '/assets/routes/home/try-it-free-banner-mb-v3-3x.webp'}`},
    {maxWidth: 768, src: `${isNotQualified ? '/assets/routes/home/try-it-free-banner-mb-v3-4x.png' : '/assets/routes/home/try-it-free-banner-mb-v3-4x.webp'}`},
  ],
  annualSpecialOffer: [
    {maxWidth: 320, src: `${isNotQualified ? '/assets/routes/home/annual-offer-banner-mb-v4-1x.png' : '/assets/routes/home/annual-offer-banner-mb-v4-1x.webp'}`},
    {maxWidth: 480, src: `${isNotQualified ? '/assets/routes/home/annual-offer-banner-mb-v4-2x.png' : '/assets/routes/home/annual-offer-banner-mb-v4-2x.webp'}`},
    {maxWidth: 576, src: `${isNotQualified ? '/assets/routes/home/annual-offer-banner-mb-v4-3x.png' : '/assets/routes/home/annual-offer-banner-mb-v4-3x.webp'}`},
    {maxWidth: 768, src: `${isNotQualified ? '/assets/routes/home/annual-offer-banner-mb-v4-4x.png' : '/assets/routes/home/annual-offer-banner-mb-v4-4x.webp'}`},
  ],
  woodbeamOneSmall: [{src: `${isNotQualified ? '/assets/routes/home/woodbeam-1-mb-v1.png' : '/assets/routes/home/woodbeam-1-mb-v1.webp'}`}],
  woodbeamOne: [{src: `${isNotQualified ? '/assets/routes/home/woodbeam-1-dt-v1.png' : '/assets/routes/home/woodbeam-1-dt-v1.webp'}`}],
  languageArts: [
    {maxWidth: 320, src: `${isNotQualified ? '/assets/routes/home/language-arts-mb-v1-1x.png' : '/assets/routes/home/language-arts-mb-v1-1x.webp'}`},
    {maxWidth: 480, src: `${isNotQualified ? '/assets/routes/home/language-arts-mb-v1-2x.png' : '/assets/routes/home/language-arts-mb-v1-2x.webp'}`},
    {maxWidth: 576, src: `${isNotQualified ? '/assets/routes/home/language-arts-mb-v1-3x.png' : '/assets/routes/home/language-arts-mb-v1-3x.webp'}`},
    {maxWidth: 768, src: `${isNotQualified ? '/assets/routes/home/language-arts-mb-v1-4x.png' : '/assets/routes/home/language-arts-mb-v1-4x.webp'}`},
    {maxWidth: 1024, src: `${isNotQualified ? '/assets/routes/home/language-arts-dt-v1-1x.png' : '/assets/routes/home/language-arts-dt-v1-1x.webp'}`},
    {maxWidth: 1280, src: `${isNotQualified ? '/assets/routes/home/language-arts-dt-v1-2x.png' : '/assets/routes/home/language-arts-dt-v1-2x.webp'}`},
    {maxWidth: 1366, src: `${isNotQualified ? '/assets/routes/home/language-arts-dt-v1-3x.png' : '/assets/routes/home/language-arts-dt-v1-3x.webp'}`},
    {maxWidth: 1440, src: `${isNotQualified ? '/assets/routes/home/language-arts-dt-v1-4x.png' : '/assets/routes/home/language-arts-dt-v1-4x.webp'}`},
    {maxWidth: 1920, src: `${isNotQualified ? '/assets/routes/home/language-arts-dt-v1-5x.png' : '/assets/routes/home/language-arts-dt-v1-5x.webp'}`}
  ],
  jewelLanguageArts: [{src: `${isNotQualified ? '/assets/routes/home/jewel-language-arts-v1.png' : '/assets/routes/home/jewel-language-arts-v1.webp'}`}],
  math: [
    {maxWidth: 320, src: `${isNotQualified ? '/assets/routes/home/math-mb-v1-1x.png' : '/assets/routes/home/math-mb-v1-1x.webp'}`},
    {maxWidth: 480, src: `${isNotQualified ? '/assets/routes/home/math-mb-v1-2x.png' : '/assets/routes/home/math-mb-v1-2x.webp'}`},
    {maxWidth: 576, src: `${isNotQualified ? '/assets/routes/home/math-mb-v1-3x.png' : '/assets/routes/home/math-mb-v1-3x.webp'}`},
    {maxWidth: 768, src: `${isNotQualified ? '/assets/routes/home/math-mb-v1-4x.png' : '/assets/routes/home/math-mb-v1-4x.webp'}`},
    {maxWidth: 1024, src: `${isNotQualified ? '/assets/routes/home/math-dt-v1-1x.png' : '/assets/routes/home/math-dt-v1-1x.webp'}`},
    {maxWidth: 1280, src: `${isNotQualified ? '/assets/routes/home/math-dt-v1-2x.png' : '/assets/routes/home/math-dt-v1-2x.webp'}`},
    {maxWidth: 1366, src: `${isNotQualified ? '/assets/routes/home/math-dt-v1-3x.png' : '/assets/routes/home/math-dt-v1-3x.webp'}`},
    {maxWidth: 1440, src: `${isNotQualified ? '/assets/routes/home/math-dt-v1-4x.png' : '/assets/routes/home/math-dt-v1-4x.webp'}`},
    {maxWidth: 1920, src: `${isNotQualified ? '/assets/routes/home/math-dt-v1-5x.png' : '/assets/routes/home/math-dt-v1-5x.webp'}`}
  ],
  jewelMath: [{src: `${isNotQualified ? '/assets/routes/home/jewel-math-v1.png' : '/assets/routes/home/jewel-math-v1.webp'}`}],
  science: [
    {maxWidth: 320, src: `${isNotQualified ? '/assets/routes/home/science-mb-v1-1x.png' : '/assets/routes/home/science-mb-v1-1x.webp'}`},
    {maxWidth: 480, src: `${isNotQualified ? '/assets/routes/home/science-mb-v1-2x.png' : '/assets/routes/home/science-mb-v1-2x.webp'}`},
    {maxWidth: 576, src: `${isNotQualified ? '/assets/routes/home/science-mb-v1-3x.png' : '/assets/routes/home/science-mb-v1-3x.webp'}`},
    {maxWidth: 768, src: `${isNotQualified ? '/assets/routes/home/science-mb-v1-4x.png' : '/assets/routes/home/science-mb-v1-4x.webp'}`},
    {maxWidth: 1024, src: `${isNotQualified ? '/assets/routes/home/science-dt-v1-1x.png' : '/assets/routes/home/science-dt-v1-1x.webp'}`},
    {maxWidth: 1280, src: `${isNotQualified ? '/assets/routes/home/science-dt-v1-2x.png' : '/assets/routes/home/science-dt-v1-2x.webp'}`},
    {maxWidth: 1366, src: `${isNotQualified ? '/assets/routes/home/science-dt-v1-3x.png' : '/assets/routes/home/science-dt-v1-3x.webp'}`},
    {maxWidth: 1440, src: `${isNotQualified ? '/assets/routes/home/science-dt-v1-4x.png' : '/assets/routes/home/science-dt-v1-4x.webp'}`},
    {maxWidth: 1920, src: `${isNotQualified ? '/assets/routes/home/science-dt-v1-5x.png' : '/assets/routes/home/science-dt-v1-5x.webp'}`}
  ],
  jewelScience: [{src: `${isNotQualified ? '/assets/routes/home/jewel-science-v1.png' : '/assets/routes/home/jewel-science-v1.webp'}`}],
  socialStudies: [
    {maxWidth: 320, src: `${isNotQualified ? '/assets/routes/home/social-studies-mb-v1-1x.png' : '/assets/routes/home/social-studies-mb-v1-1x.webp'}`},
    {maxWidth: 480, src: `${isNotQualified ? '/assets/routes/home/social-studies-mb-v1-2x.png' : '/assets/routes/home/social-studies-mb-v1-2x.webp'}`},
    {maxWidth: 576, src: `${isNotQualified ? '/assets/routes/home/social-studies-mb-v1-3x.png' : '/assets/routes/home/social-studies-mb-v1-3x.webp'}`},
    {maxWidth: 768, src: `${isNotQualified ? '/assets/routes/home/social-studies-mb-v1-4x.png' : '/assets/routes/home/social-studies-mb-v1-4x.webp'}`},
    {maxWidth: 1024, src: `${isNotQualified ? '/assets/routes/home/social-studies-dt-v1-1x.png' : '/assets/routes/home/social-studies-dt-v1-1x.webp'}`},
    {maxWidth: 1280, src: `${isNotQualified ? '/assets/routes/home/social-studies-dt-v1-2x.png' : '/assets/routes/home/social-studies-dt-v1-2x.webp'}`},
    {maxWidth: 1366, src: `${isNotQualified ? '/assets/routes/home/social-studies-dt-v1-3x.png' : '/assets/routes/home/social-studies-dt-v1-3x.webp'}`},
    {maxWidth: 1440, src: `${isNotQualified ? '/assets/routes/home/social-studies-dt-v1-4x.png' : '/assets/routes/home/social-studies-dt-v1-4x.webp'}`},
    {maxWidth: 1920, src: `${isNotQualified ? '/assets/routes/home/social-studies-dt-v1-5x.png' : '/assets/routes/home/social-studies-dt-v1-5x.webp'}`}
  ],
  jewelSocialStudies: [{src: `${isNotQualified ? '/assets/routes/home/jewel-social-studies-v1.png' : '/assets/routes/home/jewel-social-studies-v1.webp'}`}],
  woodbeamTwoSmall: [{src: `${isNotQualified ? '/assets/routes/home/woodbeam-2-mb-v1.png' : '/assets/routes/home/woodbeam-2-mb-v1.webp'}`}],
  woodbeamTwo: [{src: `${isNotQualified ? '/assets/routes/home/woodbeam-2-dt-v1.png' : '/assets/routes/home/woodbeam-2-dt-v1.webp'}`}],
  featureOne: [
    {maxWidth: 320, src: `${isNotQualified ? '/assets/routes/home/feature-1-mb-v1-1x.jpg' : '/assets/routes/home/feature-1-mb-v1-1x.webp'}`},
    {maxWidth: 480, src: `${isNotQualified ? '/assets/routes/home/feature-1-mb-v1-2x.jpg' : '/assets/routes/home/feature-1-mb-v1-2x.webp'}`},
    {maxWidth: 576, src: `${isNotQualified ? '/assets/routes/home/feature-1-mb-v1-3x.jpg' : '/assets/routes/home/feature-1-mb-v1-3x.webp'}`},
    {maxWidth: 768, src: `${isNotQualified ? '/assets/routes/home/feature-1-mb-v1-4x.jpg' : '/assets/routes/home/feature-1-mb-v1-4x.webp'}`},
    {maxWidth: 1024, src: `${isNotQualified ? '/assets/routes/home/feature-1-dt-v1-1x.jpg' : '/assets/routes/home/feature-1-dt-v1-1x.webp'}`},
    {maxWidth: 1280, src: `${isNotQualified ? '/assets/routes/home/feature-1-dt-v1-2x.jpg' : '/assets/routes/home/feature-1-dt-v1-2x.webp'}`},
    {maxWidth: 1366, src: `${isNotQualified ? '/assets/routes/home/feature-1-dt-v1-3x.jpg' : '/assets/routes/home/feature-1-dt-v1-3x.webp'}`},
    {maxWidth: 1440, src: `${isNotQualified ? '/assets/routes/home/feature-1-dt-v1-4x.jpg' : '/assets/routes/home/feature-1-dt-v1-4x.webp'}`},
    {maxWidth: 1920, src: `${isNotQualified ? '/assets/routes/home/feature-1-dt-v1-5x.jpg' : '/assets/routes/home/feature-1-dt-v1-5x.webp'}`}
  ],
  featureTwo: [
    {maxWidth: 320, src: `${isNotQualified ? '/assets/routes/home/feature-2-mb-v1-1x.jpg' : '/assets/routes/home/feature-2-mb-v1-1x.webp'}`},
    {maxWidth: 480, src: `${isNotQualified ? '/assets/routes/home/feature-2-mb-v1-2x.jpg' : '/assets/routes/home/feature-2-mb-v1-2x.webp'}`},
    {maxWidth: 576, src: `${isNotQualified ? '/assets/routes/home/feature-2-mb-v1-3x.jpg' : '/assets/routes/home/feature-2-mb-v1-3x.webp'}`},
    {maxWidth: 768, src: `${isNotQualified ? '/assets/routes/home/feature-2-mb-v1-4x.jpg' : '/assets/routes/home/feature-2-mb-v1-4x.webp'}`},
    {maxWidth: 1024, src: `${isNotQualified ? '/assets/routes/home/feature-2-dt-v1-1x.jpg' : '/assets/routes/home/feature-2-dt-v1-1x.webp'}`},
    {maxWidth: 1280, src: `${isNotQualified ? '/assets/routes/home/feature-2-dt-v1-2x.jpg' : '/assets/routes/home/feature-2-dt-v1-2x.webp'}`},
    {maxWidth: 1366, src: `${isNotQualified ? '/assets/routes/home/feature-2-dt-v1-3x.jpg' : '/assets/routes/home/feature-2-dt-v1-3x.webp'}`},
    {maxWidth: 1440, src: `${isNotQualified ? '/assets/routes/home/feature-2-dt-v1-4x.jpg' : '/assets/routes/home/feature-2-dt-v1-4x.webp'}`},
    {maxWidth: 1920, src: `${isNotQualified ? '/assets/routes/home/feature-2-dt-v1-5x.jpg' : '/assets/routes/home/feature-2-dt-v1-5x.webp'}`}
  ],
  featureThree: [
    {maxWidth: 320, src: `${isNotQualified ? '/assets/routes/home/feature-3-mb-v1-1x.jpg' : '/assets/routes/home/feature-3-mb-v1-1x.webp'}`},
    {maxWidth: 480, src: `${isNotQualified ? '/assets/routes/home/feature-3-mb-v1-2x.jpg' : '/assets/routes/home/feature-3-mb-v1-2x.webp'}`},
    {maxWidth: 576, src: `${isNotQualified ? '/assets/routes/home/feature-3-mb-v1-3x.jpg' : '/assets/routes/home/feature-3-mb-v1-3x.webp'}`},
    {maxWidth: 768, src: `${isNotQualified ? '/assets/routes/home/feature-3-mb-v1-4x.jpg' : '/assets/routes/home/feature-3-mb-v1-4x.webp'}`},
    {maxWidth: 1024, src: `${isNotQualified ? '/assets/routes/home/feature-3-dt-v1-1x.jpg' : '/assets/routes/home/feature-3-dt-v1-1x.webp'}`},
    {maxWidth: 1280, src: `${isNotQualified ? '/assets/routes/home/feature-3-dt-v1-2x.jpg' : '/assets/routes/home/feature-3-dt-v1-2x.webp'}`},
    {maxWidth: 1366, src: `${isNotQualified ? '/assets/routes/home/feature-3-dt-v1-3x.jpg' : '/assets/routes/home/feature-3-dt-v1-3x.webp'}`},
    {maxWidth: 1440, src: `${isNotQualified ? '/assets/routes/home/feature-3-dt-v1-4x.jpg' : '/assets/routes/home/feature-3-dt-v1-4x.webp'}`},
    {maxWidth: 1920, src: `${isNotQualified ? '/assets/routes/home/feature-3-dt-v1-5x.jpg' : '/assets/routes/home/feature-3-dt-v1-5x.webp'}`}
  ],
  featureFour: [
    {maxWidth: 320, src: `${isNotQualified ? '/assets/routes/home/feature-4-mb-v1-1x.jpg' : '/assets/routes/home/feature-4-mb-v1-1x.webp'}`},
    {maxWidth: 480, src: `${isNotQualified ? '/assets/routes/home/feature-4-mb-v1-2x.jpg' : '/assets/routes/home/feature-4-mb-v1-2x.webp'}`},
    {maxWidth: 576, src: `${isNotQualified ? '/assets/routes/home/feature-4-mb-v1-3x.jpg' : '/assets/routes/home/feature-4-mb-v1-3x.webp'}`},
    {maxWidth: 768, src: `${isNotQualified ? '/assets/routes/home/feature-4-mb-v1-4x.jpg' : '/assets/routes/home/feature-4-mb-v1-4x.webp'}`},
    {maxWidth: 1024, src: `${isNotQualified ? '/assets/routes/home/feature-4-dt-v1-1x.jpg' : '/assets/routes/home/feature-4-dt-v1-1x.webp'}`},
    {maxWidth: 1280, src: `${isNotQualified ? '/assets/routes/home/feature-4-dt-v1-2x.jpg' : '/assets/routes/home/feature-4-dt-v1-2x.webp'}`},
    {maxWidth: 1366, src: `${isNotQualified ? '/assets/routes/home/feature-4-dt-v1-3x.jpg' : '/assets/routes/home/feature-4-dt-v1-3x.webp'}`},
    {maxWidth: 1440, src: `${isNotQualified ? '/assets/routes/home/feature-4-dt-v1-4x.jpg' : '/assets/routes/home/feature-4-dt-v1-4x.webp'}`},
    {maxWidth: 1920, src: `${isNotQualified ? '/assets/routes/home/feature-4-dt-v1-5x.jpg' : '/assets/routes/home/feature-4-dt-v1-5x.webp'}`}
  ],
  devices: [
    {maxWidth: 320, src: `${isNotQualified ? '/assets/routes/home/devices-mb-v1-1x.png' : '/assets/routes/home/devices-mb-v1-1x.webp'}`},
    {maxWidth: 480, src: `${isNotQualified ? '/assets/routes/home/devices-mb-v1-2x.png' : '/assets/routes/home/devices-mb-v1-2x.webp'}`},
    {maxWidth: 576, src: `${isNotQualified ? '/assets/routes/home/devices-mb-v1-3x.png' : '/assets/routes/home/devices-mb-v1-3x.webp'}`},
    {maxWidth: 768, src: `${isNotQualified ? '/assets/routes/home/devices-mb-v1-4x.png' : '/assets/routes/home/devices-mb-v1-4x.webp'}`},
    {maxWidth: 1024, src: `${isNotQualified ? '/assets/routes/home/devices-dt-v1-1x.png' : '/assets/routes/home/devices-dt-v1-1x.webp'}`},
    {maxWidth: 1280, src: `${isNotQualified ? '/assets/routes/home/devices-dt-v1-2x.png' : '/assets/routes/home/devices-dt-v1-2x.webp'}`},
    {maxWidth: 1366, src: `${isNotQualified ? '/assets/routes/home/devices-dt-v1-3x.png' : '/assets/routes/home/devices-dt-v1-3x.webp'}`},
    {maxWidth: 1440, src: `${isNotQualified ? '/assets/routes/home/devices-dt-v1-4x.png' : '/assets/routes/home/devices-dt-v1-4x.webp'}`},
    {maxWidth: 1920, src: `${isNotQualified ? '/assets/routes/home/devices-dt-v1-5x.png' : '/assets/routes/home/devices-dt-v1-5x.webp'}`}
  ],
  miniRegScrollBottom: [
    {maxWidth: 320, src: `${isNotQualified ? '/assets/routes/home/minireg-scroll-bottom-mb-v1-1x.png' : '/assets/routes/home/minireg-scroll-bottom-mb-v1-1x.webp'}`},
    {maxWidth: 480, src: `${isNotQualified ? '/assets/routes/home/minireg-scroll-bottom-mb-v1-2x.png' : '/assets/routes/home/minireg-scroll-bottom-mb-v1-2x.webp'}`},
    {maxWidth: 576, src: `${isNotQualified ? '/assets/routes/home/minireg-scroll-bottom-mb-v1-3x.png' : '/assets/routes/home/minireg-scroll-bottom-mb-v1-3x.webp'}`},
    {maxWidth: 768, src: `${isNotQualified ? '/assets/routes/home/minireg-scroll-bottom-mb-v1-4x.png' : '/assets/routes/home/minireg-scroll-bottom-mb-v1-4x.webp'}`},
    {maxWidth: 1024, src: `${isNotQualified ? '/assets/routes/home/minireg-scroll-bottom-dt-v1-1x.png' : '/assets/routes/home/minireg-scroll-bottom-dt-v1-1x.webp'}`},
    {maxWidth: 1280, src: `${isNotQualified ? '/assets/routes/home/minireg-scroll-bottom-dt-v1-2x.png' : '/assets/routes/home/minireg-scroll-bottom-dt-v1-2x.webp'}`},
    {maxWidth: 1366, src: `${isNotQualified ? '/assets/routes/home/minireg-scroll-bottom-dt-v1-3x.png' : '/assets/routes/home/minireg-scroll-bottom-dt-v1-3x.webp'}`},
    {maxWidth: 1440, src: `${isNotQualified ? '/assets/routes/home/minireg-scroll-bottom-dt-v1-4x.png' : '/assets/routes/home/minireg-scroll-bottom-dt-v1-4x.webp'}`},
    {maxWidth: 1920, src: `${isNotQualified ? '/assets/routes/home/minireg-scroll-bottom-dt-v1-5x.png' : '/assets/routes/home/minireg-scroll-bottom-dt-v1-5x.webp'}`}
  ],
  facebook: [{src: `${isNotQualified ? '/assets/templates/social-media-icons/facebook-v1.png' : '/assets/templates/social-media-icons/facebook-v1.webp'}`}],
  instagram: [{src: `${isNotQualified ? '/assets/templates/social-media-icons/instagram-v1.png' : '/assets/templates/social-media-icons/instagram-v1.webp'}`}],
  twitter: [{src: `${isNotQualified ? '/assets/templates/social-media-icons/twitter-v1.png' : '/assets/templates/social-media-icons/twitter-v1.webp'}`}],
  youtube: [{src: `${isNotQualified ? '/assets/templates/social-media-icons/youtube-v1.png' : '/assets/templates/social-media-icons/youtube-v1.webp'}`}],
  learnMoreButton: [
    {maxWidth: 320, src: `${isNotQualified ? '/assets/routes/home/button-learn-more-mb-v1-1x.png' : '/assets/routes/home/button-learn-more-mb-v1-1x.webp'}`},
    {maxWidth: 480, src: `${isNotQualified ? '/assets/routes/home/button-learn-more-mb-v1-2x.png' : '/assets/routes/home/button-learn-more-mb-v1-2x.webp'}`},
    {maxWidth: 576, src: `${isNotQualified ? '/assets/routes/home/button-learn-more-mb-v1-3x.png' : '/assets/routes/home/button-learn-more-mb-v1-3x.webp'}`},
    {maxWidth: 768, src: `${isNotQualified ? '/assets/routes/home/button-learn-more-mb-v1-4x.png' : '/assets/routes/home/button-learn-more-mb-v1-4x.webp'}`},
    {maxWidth: 1024, src: `${isNotQualified ? '/assets/routes/home/button-learn-more-dt-v1-1x.png' : '/assets/routes/home/button-learn-more-dt-v1-1x.webp'}`},
    {maxWidth: 1280, src: `${isNotQualified ? '/assets/routes/home/button-learn-more-dt-v1-2x.png' : '/assets/routes/home/button-learn-more-dt-v1-2x.webp'}`},
    {maxWidth: 1366, src: `${isNotQualified ? '/assets/routes/home/button-learn-more-dt-v1-3x.png' : '/assets/routes/home/button-learn-more-dt-v1-3x.webp'}`},
    {maxWidth: 1440, src: `${isNotQualified ? '/assets/routes/home/button-learn-more-dt-v1-4x.png' : '/assets/routes/home/button-learn-more-dt-v1-4x.webp'}`},
    {maxWidth: 1920, src: `${isNotQualified ? '/assets/routes/home/button-learn-more-dt-v1-5x.png' : '/assets/routes/home/button-learn-more-dt-v1-5x.webp'}`}
  ],
  miniRegScroll: [
    {maxWidth: 320, src: `${isNotQualified ? '/assets/routes/home/minireg-scroll-mb-v1-1x.png' : '/assets/routes/home/minireg-scroll-mb-v1-1x.webp'}`},
    {maxWidth: 480, src: `${isNotQualified ? '/assets/routes/home/minireg-scroll-mb-v1-2x.png' : '/assets/routes/home/minireg-scroll-mb-v1-2x.webp'}`},
    {maxWidth: 576, src: `${isNotQualified ? '/assets/routes/home/minireg-scroll-mb-v1-3x.png' : '/assets/routes/home/minireg-scroll-mb-v1-3x.webp'}`},
    {maxWidth: 768, src: `${isNotQualified ? '/assets/routes/home/minireg-scroll-mb-v1-4x.png' : '/assets/routes/home/minireg-scroll-mb-v1-4x.webp'}`},
    {maxWidth: 1024, src: `${isNotQualified ? '/assets/routes/home/minireg-scroll-dt-v1-1x.png' : '/assets/routes/home/minireg-scroll-dt-v1-1x.webp'}`},
    {maxWidth: 1280, src: `${isNotQualified ? '/assets/routes/home/minireg-scroll-dt-v1-2x.png' : '/assets/routes/home/minireg-scroll-dt-v1-2x.webp'}`},
    {maxWidth: 1366, src: `${isNotQualified ? '/assets/routes/home/minireg-scroll-dt-v1-3x.png' : '/assets/routes/home/minireg-scroll-dt-v1-3x.webp'}`},
    {maxWidth: 1440, src: `${isNotQualified ? '/assets/routes/home/minireg-scroll-dt-v1-4x.png' : '/assets/routes/home/minireg-scroll-dt-v1-4x.webp'}`},
    {maxWidth: 1920, src: `${isNotQualified ? '/assets/routes/home/minireg-scroll-dt-v1-5x.png' : '/assets/routes/home/minireg-scroll-dt-v1-5x.webp'}`}
  ],
  modalCloseRibbon: [
    {maxWidth: 320, src: `${isNotQualified ? '/assets/routes/home/modal-close-ribbon-mb-v1-1x.png' : '/assets/routes/home/modal-close-ribbon-mb-v1-1x.webp'}`},
    {maxWidth: 480, src: `${isNotQualified ? '/assets/routes/home/modal-close-ribbon-mb-v1-2x.png' : '/assets/routes/home/modal-close-ribbon-mb-v1-2x.webp'}`},
    {maxWidth: 576, src: `${isNotQualified ? '/assets/routes/home/modal-close-ribbon-mb-v1-3x.png' : '/assets/routes/home/modal-close-ribbon-mb-v1-3x.webp'}`},
    {maxWidth: 768, src: `${isNotQualified ? '/assets/routes/home/modal-close-ribbon-mb-v1-4x.png' : '/assets/routes/home/modal-close-ribbon-mb-v1-4x.webp'}`},
    {maxWidth: 1024, src: `${isNotQualified ? '/assets/routes/home/modal-close-ribbon-dt-v1-1x.png' : '/assets/routes/home/modal-close-ribbon-dt-v1-1x.webp'}`},
    {maxWidth: 1280, src: `${isNotQualified ? '/assets/routes/home/modal-close-ribbon-dt-v1-2x.png' : '/assets/routes/home/modal-close-ribbon-dt-v1-2x.webp'}`},
    {maxWidth: 1366, src: `${isNotQualified ? '/assets/routes/home/modal-close-ribbon-dt-v1-3x.png' : '/assets/routes/home/modal-close-ribbon-dt-v1-3x.webp'}`},
    {maxWidth: 1440, src: `${isNotQualified ? '/assets/routes/home/modal-close-ribbon-dt-v1-4x.png' : '/assets/routes/home/modal-close-ribbon-dt-v1-4x.webp'}`},
    {maxWidth: 1920, src: `${isNotQualified ? '/assets/routes/home/modal-close-ribbon-dt-v1-5x.png' : '/assets/routes/home/modal-close-ribbon-dt-v1-5x.webp'}`}
  ]
};

export {
  imageConfig
};
