import styles from './template.css';
import template from './template';
import {AoflElement, property, customElement} from '@aofl/element';
import {routerInstance, PathUtils} from '@aofl/router';
import {clickTrackerMixin} from '../click-tracker-mixin';
import {environment} from '../constants-enumerate';
import {AppCallService} from '../app-call-service';

/**
 * @private
 * @type {RegExp}
 */
const PUBLIC_PATH_REGEX = new RegExp('^' + __webpack_public_path__, 'i'); // eslint-disable-line
const EXTERNAL_LINK_REGEX = /^https?:\/\//;

/**
 * @extends {AoflElement}
 */
@customElement('new-member-link-to')
class LinkToElement extends clickTrackerMixin(AoflElement) {
  /**
   * @type {String}
   * @readonly
   */
  static is = 'new-member-link-to';

  /**
   * @type {String}
   */
  @property({converter(value) {
    if (EXTERNAL_LINK_REGEX.test(value) || !PUBLIC_PATH_REGEX.test(value)) {
      return value;
    }
    const href = PathUtils.removeLeadingSlash(value.replace(PUBLIC_PATH_REGEX, ''));
    return '/' + PathUtils.removeLeadingSlash(__webpack_public_path__ + href, ''); // eslint-disable-line
  }})
  href = '';

  @property({type: String, attribute: true})
  target = '';

  @property({type: Boolean, attribute: 'show-parent-gate'})
  showParentGate;

  /**
   * @type {Boolean}
   */
  @property({type: Boolean})
  ['disabled'] = false;

  /**
   * @type {Boolean}
   */
  @property({type: Boolean})
  ['track-url'] = false;

  /**
   * @type {Boolean}
   */
  @property({type: Boolean})
  ['track-url-partial'] = false;

  /**
   * @private
   * @type {Function}
   */
  routerUnsubscribe = () => {};

  /**
   * @private
   * @type {Boolean}
   */
  ctrlClick = false;

  @property({type: Boolean, attribute: 'tab-focus-disabled'})
  'tabFocusDisabled' = false;

  @property({type: Boolean})
  'tab-outline-blue' = false;

  @property({type: Boolean})
  'tab-outline-white' = false;

  /**
   * @param {Event} e
   * @return {void}
   */
  clickHandler(e) {
    e.preventDefault();
    if (this.target) {
      if (environment.IN_APP) {
        const origin = window.location.origin;
        let url = origin + this.href;
        if (/https/.exec(this.href) !== null) {
          url = this.href;
        }

        AppCallService.navigate(url, this.target);
      } else {
        window.open(this.href, this.target);
      }
    } else if (this.orientationchanged) {
      window.location = this.href;
    } else {
      routerInstance.navigate(this.href, {});
    }
  }

  /**
   * @private
   * @type {Function}
   * @param {Event} e
   */
  keydownHandler = (e) => {
    if (e.which === 17 || e.which === 91) {
      this.ctrlClick = true;
      window.addEventListener('keyup', this.keyupHandler);
    }
  };

  /**
   * @private
   * @type {Function}
   * @param {Event} e
   */
  keyupHandler = (e) => {
    if (e.which === 17 || e.which === 91) {
      this.ctrlClick = false;
      window.addEventListener('keyup', this.keyupHandler);
    }
  };

  /**
   * @private
   * @type {Function}
   * @param {Event} e
   */
  focusHandler = (e) => {
    this.ctrlClick = false;
  }

  /**
   * Check if path matches href.
   *
   * @private
   * @param {String} path
   */
  checkActive(path) {
    const cleanHref = PathUtils.removeTrailingSlash(PathUtils.cleanPath(this.href)) + '/';
    const cleanPath = PathUtils.removeTrailingSlash(PathUtils.cleanPath(path)) + '/';

    if (cleanHref === cleanPath) {
      this.classList.add('active');
    } else if (this['track-url-partial'] && cleanPath.indexOf(cleanHref) === 0) {
      this.classList.add('active');
    } else {
      this.classList.remove('active');
    }
  }

  /**
   *
   */
  connectedCallback() {
    super.connectedCallback();

    window.addEventListener('keydown', this.keydownHandler);
    window.addEventListener('focus', this.focusHandler);
    if (this['track-url'] || this['track-url-partial']) {
      this.checkActive(location.pathname);
      this.routerUnsubscribe = routerInstance.after((request, response, next) => {
        this.checkActive(response.to);
        next(response);
      });
    }
  }

  /**
   *
   */
  firstUpdated() {
    super.firstUpdated();

    if (this['tab-outline-blue']) {
      this.classList.add('tab-outline-blue');
    } else {
      this.classList.remove('tab-outline-blue');
    }

    if (this['tab-outline-white']) {
      this.classList.add('tab-outline-white');
    } else {
      this.classList.remove('tab-outline-white');
    }
  }

  /**
   *
   */
  disconnectedCallback() {
    window.removeEventListener('keyup', this.keyupHandler);
    window.removeEventListener('keydown', this.keydownHandler);
    window.removeEventListener('focus', this.focusHandler);
    this.routerUnsubscribe();
    super.disconnectedCallback();
  }

  /**
   *
   * @return {Object}
   */
  render() {
    return super.render(template, [styles]);
  }
}

export default LinkToElement;
