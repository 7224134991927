import styles from './template.css';
import template from './template';
import {AoflElement, customElement, property} from '@aofl/element';
import {isInViewportMixin} from '@aofl/component-utils';
import {isMobile} from '@aoflmkt/is-mobile';
import {accessibilityMixin} from '../../../../modules/accessibility-mixin';
import {clickTrackerMixin} from '../../../../modules/click-tracker-mixin';
import {environment} from '../../../../modules/constants-enumerate';

const Mixin = isInViewportMixin(clickTrackerMixin(accessibilityMixin(AoflElement)));

/**
 * @summary LoginHeader
 * @class LoginHeader
 * @extends {AoflElement}
 */
@customElement('login-header')
class LoginHeader extends Mixin {
  /**
   * @readonly
   */
  static is = 'login-header';

  @property({type: Boolean, attribute: false})
  inApp = environment.IN_APP;

  @property({type: Boolean, attribute: false})
  displayCloseAppButton = (environment.IN_APP && environment.IS_DESKTOP);

  @property({type: Boolean, attribute: 'prevent-redirect'})
  preventRedirect = false;

  @property({type: Boolean, attribute: 'hide-back-button'})
  hideBackButton = false;

  /**
   *
   */
  connectedCallback() {
    super.connectedCallback();
    this.displayBackButton = (isMobile.apple.device && environment.IN_APP && !this.hideBackButton);
  }

  /**
   *
   * @return {Object}
   */
  render() {
    return super.render(template, [styles]);
  }
}

export default LoginHeader;
