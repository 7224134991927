import styles from './template.css';
import template from './template';
import {AoflElement, customElement} from '@aofl/element';
import {isInViewportMixin} from '@aofl/component-utils';

/**
 * @summary LazyLoadBg
 * @class LazyLoadBg
 * @extends {AoflElement}
 */
@customElement('lazy-load-bg')
class LazyLoadBg extends isInViewportMixin(AoflElement) {
  /**
   *
   * Creates an instance of LazyLoadBg.
   */
  constructor() {
    super();
    this.dynamicTemplate = () => '';
    this.widthThreshold = 0.15;
    this.heightThreshold = 1;

    if (window.aofljsConfig.__prerender__) {
      this.dynamicTemplate = template;
    }
  }

  /**
   * @readonly
   */
  static is = 'lazy-load-bg';

  /**
   * Updates the template to the transcluded content and adds the class
   * 'scrolled-into' which can be used for lazy-loading css bg images
   */
  firstWithinViewport() {
    this.stopIsInViewportCheck();
    this.dynamicTemplate = template;
    this.classList.add('scrolled-into');
    this.requestUpdate();
  }

  /**
   *
   * @return {Object}
   */
  render() {
    return super.render(this.dynamicTemplate, [styles]);
  }
}

export default LazyLoadBg;
