/**
 * @summary sdo-library-info
 * @version 1.0.0
 * @since 1.0.0
 * @author Paul Gi <paul.gi@aofl.com>
 */
import {Sdo, storeInstance, state} from '@aofl/store';
import {cacheNamespaces} from './constants-enumerate';
import {CacheManager, cacheTypeEnumerate} from '@aofl/cache-manager';

const librarySessionData = new CacheManager(cacheNamespaces.LIBRARY_SESSION, cacheTypeEnumerate.LOCAL);
const libraryData = librarySessionData.getItem(cacheNamespaces.LIBRARY_SESSION);

/**
 * @type {String}
 * @private
 */
const NAMESPACE = 'library-info';

/**
 * @extends {Sdo}
 */
class LibraryInfoSdo extends Sdo {
  /**
   * @static
   * @type {String}
   */
  static namespace = NAMESPACE;

  /**
   * @static
   * @type {Object}
   */
  @state()
  libraryInfo = {
    patronProductId: libraryData?.patronProductId || '',
    checkoutId: libraryData?.checkoutId || '',
    libraryName: libraryData?.libraryName || ''
  };
}

/**
 * @type {LibraryInfoSdo}
 */
const libraryInfoSdo = new LibraryInfoSdo();
storeInstance.addState(libraryInfoSdo);

export {
  libraryInfoSdo
};
