import styles from './template.css';
import template from './template';
import {AoflElement, customElement, property} from '@aofl/element';
import {isMobile} from '@aoflmkt/is-mobile';
import {isInViewportMixin} from '@aofl/component-utils';
import {accessibilityMixin} from '../accessibility-mixin';
import {clickTrackerMixin} from '../click-tracker-mixin';
import {environment} from '../constants-enumerate';

const Mixin = isInViewportMixin(clickTrackerMixin(accessibilityMixin(AoflElement)));

/**
 * @summary NewHeader
 * @class NewHeader
 * @extends {AoflElement}
 */
@customElement('new-header')
class NewHeader extends Mixin {
  /**
   * Creates an instance of NewHeader.
   */
  constructor() {
    super();
  }

  /**
   * @readonly
   */
  static is = 'new-header';

  /**
   * @return {Object}
   */
  @property({type: Boolean, attribute: 'prevent-redirect'})
  preventRedirect = false;

  @property({type: Boolean, attribute: 'is-nmhp-logo'})
  isNmhpLogo = false;

  @property({type: Boolean, attribute: 'hide-back-button'})
  hideBackButton = false;

  /**
   *
   */
  connectedCallback() {
    super.connectedCallback();
    this.displayBackButton = (isMobile.apple.device && environment.IN_APP && !this.hideBackButton);
    this.displayCloseAppButton = (environment.IN_APP && environment.IS_DESKTOP);
  }

  /**
   *
   * @return {Object}
   */
  render() {
    return super.render(template, [styles]);
  }
}

export default NewHeader;
